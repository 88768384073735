import {
  HomeOutlined,
  InfoCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";

import {
  Button,
  FloatButton,
  Form,
  Image,
  Input,
  message,
  Modal,
  Select,
  Spin
} from "antd";
import { useEffect, useRef, useState } from "react";

import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { db } from "../firebase";
import {
  contentCreatorAIState,
  contentCreatorImageState,
} from "../recoil/contentCreatorStates";
import { AudioGen } from "./AudioGen";

const AIAnalyser = () => {
  const [promptForm] = Form.useForm();
  const [isVis, setIsVis] = useState("image");
  const [isWaiting, setIsWaiting] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [textData, setTextData] = useState<null | string>(null);
  const [select, setSelect] = useState("OIA");
  const [content, setContent] = useRecoilState(contentCreatorAIState);
  const [imageForm] = Form.useForm();
  const [imagePrompt, setImagePrompt] = useRecoilState(
    contentCreatorImageState
  );
  const nav = useNavigate();
  const [imageData, setImageData] = useState<string | null>(null);
  const opt = [
    { value: "OIA", label: "Write an OIA" },
    { value: "Summary", label: "Summarise this text" },
    { value: "Image", label: "Image prompt from text" },
    { value: "Custom", label: "Custom request" },
    { value: "Talk", label: "Talk" },
  ];

  const [socketUrl, setSocketUrl] = useState("");

  const fetchAI = async (refinedPrompt: string): Promise<string> => {
    const result = await fetch(`https://api.aimediacommunications.org/gen`, {
      headers: {
        authorization: socketUrl,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ prompt: refinedPrompt, type: "text" }),
      method: "POST",
    })
      // req({ body: { prompt: refinedPrompt } })
      .then(async (res) => {
        const resData = await res.json();
        return resData.text;
      })
      .catch((err) => {
        console.error(err);
        return "Server error";
      });

    return result;
  };

  useEffect(() => {
    getDoc(doc(db, "socket", "sdfgrerretgrgre"))
      .then((snap) => {
        snap.data()?.auth && setSocketUrl(snap.data()?.auth);
      })
      .catch((err) => {
        message.error("Failed to get socket url");
      });
  }, []);

  const [transcript, setTranscript] = useState<string>("");
  const [isListening, setIsListening] = useState<boolean>(false);
  const recognitionRef = useRef<SpeechRecognition | null>(null);

  const handleSpeak = async (text: string) => {
    if (!text.trim()) {
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);

    // Event listener for when speech starts
    utterance.onstart = () => {
      console.log("Speech synthesis started.");
    };

    // Event listener for when speech ends
    const complete = (utterance.onend = () => {
      console.log("Speech synthesis ended.");
      return true;
    });

    // Event listener for errors
    utterance.onerror = (event) => {
      console.error("Speech synthesis error:", event.error);
    };

    // Set voice and other properties (optional)
    utterance.lang = "en-US"; // Language
    utterance.pitch = 1; // Pitch (default 1)
    utterance.rate = 1; // Rate of speech (default 1)
    utterance.volume = 1; // Volume (default 1)

    window.speechSynthesis.speak(utterance);
    return await complete;
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Start speech recognition
   * @param isListening - Whether or not to keep listening after one speech recognition
   * @returns void
   */
  /******  1bdc047e-55f5-42af-99b0-99199a6450d5  *******/

  useEffect(() => {
    console.log("Load recognition");
    const SpeechRecognition =
      (window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error("Speech Recognition API is not supported in this browser.");
    }

    const recognition = new SpeechRecognition();
    recognition.lang = "en-US"; // Set language
    recognition.interimResults = false; // Only return final results
    recognition.maxAlternatives = 1; // Max number of recognition alternatives

    recognitionRef.current = recognition;

    recognition.onresult = async (event: SpeechRecognitionEvent) => {
      const result = event.results[0][0].transcript;
      setTranscript(result);
      const text = await fetchAI(
        `You are an expert and need to return a paragraph, that will be converted to speech, answering the following question: ${result}`
      );
      handleSpeak(text)
        .then((res) => {})
        .then(() => listen());
      console.log("Recognized Speech:", result);
    };

    recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      console.error("Speech recognition error:", event.error);
    };

    // Event listener for end
    recognition.onend = () => {
      console.log("Speech recognition ended.");

      if (isListening) {
        startListening();
      }
    };

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, [socketUrl]);

  const listen = () => {
    if (!recognitionRef.current) {
      return;
    }
    recognitionRef.current.start();
  };

  const startListening = () => {
    setIsListening(true);

    if (recognitionRef.current) {
      recognitionRef.current.start();

      console.log("Speech recognition started.");
    }
  };

  // Stop speech recognition
  const stopListening = () => {
    setIsListening(false);
    if (recognitionRef.current) {
      recognitionRef.current.stop();

      console.log("Speech recognition stopped.");
    }
  };

  const handleSubmit = () => {
    promptForm
      .validateFields()
      .then(async (values) => {
        setIsWaiting(true);
        let refinedPrompt: null | string = null;

        // const req = httpsCallable(functions, "getAIText");

        if (values.selector === "OIA") {
          refinedPrompt = `Write an Official Information Act 1982 request asking for the following information: ${values.prompt}`;
        } else if (values.selector === "Summary") {
          refinedPrompt = `Write a summary of the following text: ${values.prompt}`;
        } else if (values.selector === "Image") {
          refinedPrompt = `Write an image prompt to generate an engaging image that communicates the ideas in the following text: ${values.prompt}`;
        } else {
          refinedPrompt = values.prompt;
        }

        if (!refinedPrompt) return;

        const text = await fetchAI(refinedPrompt);
        setTextData(text);
        setIsWaiting(false);
      })
      .catch((err) => console.error(err));
  };

  const downloadImage = (dataUrl: string) => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "Image";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getImage = () => {
    setIsWaiting(true);
    imageForm
      .validateFields()
      .then(async (values) => {
        await fetch(`https://api.aimediacommunications.org/gen`, {
          headers: {
            authorization: socketUrl,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt: values.prompt, type: "image" }),
          method: "POST",
        })
          .then(async (res) => {
            const resData = await res.json();
            message.success("Image generated");
            setIsWaiting(false);
            setImageData(`data:image/png;base64,${resData.image}`);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleImagePrompt = async () => {
    const finalPrompt = imageForm.getFieldValue("prompt");
    await fetchAI(
      `Write an image prompt to generate an engaging image that communicates the ideas in the following text: ${finalPrompt}`
    )
      .then((text) => imageForm.setFieldValue("prompt", text))
      .catch((err: any) => console.log(err));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        // maxWidth: "1200px",
        padding: "5vw",
        backgroundColor: "white",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "1rem",
          display: "flex",
          gap: "1rem",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Button icon={<InfoCircleOutlined />} onClick={() => setIsInfo(true)} />
        <Button onClick={() => setIsVis("image")}>Image</Button>
        <Button onClick={() => setIsVis("audio")}>Audio</Button>
        <Button onClick={() => setIsVis("text")}>Text</Button>
        <Button onClick={() => nav("/analysis-tools")}>Analysis</Button>
      
      </div>
      {isVis === "image" && (
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <div style={{ width: "50%" }}>
            <Form
              style={{ width: "100%" }}
              form={imageForm}
              initialValues={{ prompt: imagePrompt && imagePrompt }}
            >
              <Form.Item
                name="prompt"
                rules={[{ required: true, message: "You must have a prompt" }]}
              >
                <Input.TextArea
                  style={{ width: "100%" }}
                  rows={15}
                  placeholder="Image prompt"
                  onChange={() =>
                    setImagePrompt(() => imageForm.getFieldValue("prompt"))
                  }
                />
              </Form.Item>
            </Form>
            <Button disabled={isWaiting} onClick={() => handleImagePrompt()}>
              Get Prompt
            </Button>
            <Button disabled={isWaiting} onClick={() => getImage()}>
              Get Image
            </Button>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {imageData && (
              <Image src={imageData} onClick={() => downloadImage(imageData)} />
            )}
            {isWaiting && (
              <div
                style={{ width: "50%", textAlign: "center", padding: "1rem" }}
              >
                <Spin size="large" />
                <div style={{ color: "#70A0FF" }}>Please wait...</div>
              </div>
            )}
          </div>
        </div>
      )}
      {isVis === "audio" && <AudioGen />}
      {isVis === "text" && (
        <div style={{ width: "100%", maxWidth: "1200px" }}>
          <div style={{ padding: "2rem" }}>
            <h1>Speech to Text</h1>
            <p>Transcript: {transcript}</p>
            <Button onClick={startListening} disabled={isListening}>
              Start Listening
            </Button>
            <Button onClick={stopListening} disabled={!isListening}>
              Stop Listening
            </Button>
            <Form
              form={promptForm}
              initialValues={{ prompt: content && content }}
            >
              <Form.Item name="selector">
                <Select
                  options={opt}
                  placeholder="Select prompt type"
                  onChange={() =>
                    setSelect(promptForm.getFieldValue("selector"))
                  }
                />
              </Form.Item>
              {select !== "Talk" && (
                <Form.Item name="prompt">
                  <Input.TextArea
                    onChange={(e) =>
                      setContent(() => promptForm.getFieldValue("prompt"))
                    }
                    rows={10}
                    placeholder="Write your prompt"
                  ></Input.TextArea>
                </Form.Item>
              )}
            </Form>
            {content && <div>{content.split(" ").length - 1} words</div>}
            <Button
              disabled={isWaiting}
              icon={<SendOutlined />}
              onClick={() => handleSubmit()}
            />
            {isWaiting && (
              <div
                style={{ width: "100%", textAlign: "center", padding: "1rem" }}
              >
                <Spin size="large" />
                <div style={{ color: "#70A0FF" }}>Please wait...</div>
              </div>
            )}
            {textData && (
              <div
                style={{ padding: "1rem", height: "100%", overflow: "auto" }}
              >
                <div>Result</div>
                <div
                  style={{
                    display: "block",
                    whiteSpace: "pre-wrap",
                    border: "1px solid rgb(135,135,135)",
                    padding: ".5rem",
                    borderRadius: ".25rem",
                    height: "500px",
                    width: "100%",
                  }}
                  onClick={() => navigator.clipboard.writeText(textData)}
                >
                  {textData}
                  {/* <MarkdownEditor
                    value={textData}
                    style={{
                      maxWidth: "800px",
                      lineBreak: "anywhere",
                      whiteSpace: "pre-wrap",
                    }}
                    width="500px"
                    // onChange={(value, viewUpdate) => {
                    //   console.log(value, viewUpdate);
                    // }}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        open={isInfo}
        onCancel={() => setIsInfo(false)}
        footer={null}
        title="Using AI Assist"
      >
        <div>AI Assist uses ChatGPT under-the-hood.</div>
        <div style={{ paddingTop: "1rem" }}>
          There are four options selectable to help speed up requests that will
          preface the requests with the following text:
        </div>

        <div
          style={{
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <ul>
            <li style={{ fontWeight: "bold" }}>OIA</li>
            <div>
              Write an Official Information Act 1982 request asking for the
              following information: ...
            </div>
            <li style={{ fontWeight: "bold" }}>Summary</li>
            <div>Write a summary of the following text: ...</div>
            <li style={{ fontWeight: "bold" }}>Image</li>
            <div>
              Write an image prompt to generate an engaging image that
              communicates the ideas in the following text: ...
            </div>
            <li style={{ fontWeight: "bold" }}>Custom request</li>
            <div>There is no predefined text in the custom request</div>
          </ul>
        </div>
      </Modal>
      <FloatButton
        icon={<HomeOutlined />}
        onClick={() => {
          nav("/tools");
        }}
      />
    </div>
  );
};
export default AIAnalyser;
