import {
    Button,
    Form,
    Image,
    Input,
    message,
    Spin,
    Upload,
    UploadProps,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { db, } from "../firebase";
import uploadImage from "./../FileUpload/upload.png";
import { LoadingOutlined } from "@ant-design/icons";

export const FileUploads = () => {
  const [newFile, setNewFile] = useState<RcFile | null>(null);
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [api, setApi] = useState<null | { key: string; url: string }>(null);
  const [placeholder, setPlaceholder] = useState<string>(
    "File name or title..."
  );
  const [uploadForm] = Form.useForm();

  useEffect(() => {
    getDoc(doc(db, "socket/gfdjhkhfdldrjg"))
      .then((snapDoc) => {
        setApi(() => ({ ...snapDoc.data() } as { key: string; url: string }));
      })
      .then(() => message.info("Connection Info Received."))
      .catch((err) => console.log(err));
  }, []);

  const parseJsonRcFile = (file: RcFile): Promise<Record<string, any>> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Handle file reading
      reader.onload = () => {
        try {
          // Parse JSON content
          const jsonData = JSON.parse(reader.result as string);
          resolve(jsonData);
        } catch (error: any) {
          reject(new Error("Failed to parse JSON file: " + error.message));
        }
      };

      // Handle file reading errors
      reader.onerror = () => {
        reject(new Error("File reading failed."));
      };

      // Read the file as text
      reader.readAsText(file);
    });
  };

  const handleSaveNew = (url: string) => {
    const newId = nanoid();

    uploadForm
      .validateFields()
      .then((values) => {

      })
      .then(() => {
          setUploading(false);
          setEndPoint(null);
          setNewFile(null);
          setPlaceholder("Add another file...");
          message.success("File uploaded");
          uploadForm.resetFields();
        })
      .catch((err) => {
        setUploading(false);
        setEndPoint(null);
        setNewFile(null);
        setPlaceholder("File name or title...");
        message.success("Failed to upload");

        console.error(err);
      });
  };

  const props: UploadProps = {
    beforeUpload: (file: RcFile) => {
      console.log(file.type);
      const isCorrectFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "audio/mpeg" ||
        file.type === "audio/wav" ||
        file.type === "audio/ogg" ||
        file.type === "application/pdf" ||
        file.type === "audio/mp4" ||
        file.type === "audio/webm" ||
        file.type === "audio/3gp" ||
        file.type === "audio/3gpp" ||
        file.type === "audio/m4a" ||
        file.type === "audio/aac" ||
        file.type === "audio/x-m4a" ||
        file.type === "video/mp4" ||
        file.type === "video/ogg" ||
        file.type === "video/webm" ||
        file.type === "application/json";

      if (!isCorrectFileType) {
        message.error("Sorry, file type not supported");
      } else {
        //setImageName(file.name);
        setNewFile(file);
      }

      return isCorrectFileType;
    },
    accept:
      "image/png, image/jpg, image/jpeg, application/json, application/pdf, audio/ogg, audio/wav, audio/mpeg, audio/mp4, audio/webm, audio/x-m4a, audio/3gp, audio/3gpp ,audio/m4a, audio/aac, video/mp4, video/ogg, video/webm",
    customRequest: async () => {
      if (newFile) {
        try {
          const jsonData = await parseJsonRcFile(newFile);
          console.log("Parsed JSON Data:", jsonData);

          setUploading(true);

          if (!api?.key || !api.url) return;
          console.log(`Fetch via ${endPoint}`)
          fetch(`${api?.url}${endPoint}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: api?.key,
            },
            body: JSON.stringify({
              data: jsonData,
            }),
          }).then(async (res) => {
           const result = await res.text()
            message.success(result)
            setUploading(false);
            setEndPoint(null);
            setNewFile(null);
            setPlaceholder("Endpoint...");
          })
          .catch((err) => {
            setUploading(false);
            setEndPoint(null);
            setNewFile(null);
            setPlaceholder("Endpoint...");
            message.error("Failed to upload");
            console.error(err);
          });
        } catch (error: any) {
          console.error("Error:", error.message);
        }
      }
    },
    maxCount: 1,
    showUploadList: false,
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "15",
        backgroundColor: "rgba(0,0,0,0.9)",
      }}
    >
      <div style={{ width: "70%", maxWidth: "300px" }}>
        {uploading ? (
          <div>
            <Spin indicator={<LoadingOutlined spin />} size="large" />
            <div style={{ color: "white" }}>Uploading...</div>
          </div>
        ) : (
          <Form
            form={uploadForm}
            onChange={() => setEndPoint(uploadForm.getFieldValue("name"))}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input a file name!",
                },
              ]}
            >
              <Input placeholder={placeholder} />
            </Form.Item>
            {endPoint && (
              <Upload {...props}>
                <Button type="text">
                  <Image
                    preview={false}
                    src={uploadImage}
                    style={{ width: "100%" }}
                  />
                </Button>
              </Upload>
            )}
          </Form>
        )}
      </div>
    </div>
  );
};
